var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 pl-2"},[_c('h5',{staticClass:"text-center"},[_vm._v("\n        Resumen de denuncias recibidas período\n        "),_c('strong',[_vm._v(_vm._s(`${_vm.startText} - ${_vm.endText}`))])]),_vm._v(" "),_c('el-collapse',{staticClass:"w-100 border-bottom-0 mb-3",model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[_c('el-collapse-item',{staticClass:"div-filters",attrs:{"name":"1"}},[_c('template',{slot:"title"},[_c('h5',{staticClass:"pl-3"},[_c('i',{class:`${
                            _vm.activeNames == '1'
                                ? 'el-icon-minus'
                                : 'el-icon-plus'
                        } mr-2`}),_vm._v("\n                    "+_vm._s(_vm.activeNames == "1"
                            ? "Ocultar filtros"
                            : "Ver filtros")+"\n                ")])]),_vm._v(" "),_c('div',{staticClass:"row mx-2 mb-4"},[_c('div',{staticClass:"col-md-3 my-1 p-1"},[_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"monthrange","align":"right","unlink-panels":"","range-separator":"-","start-placeholder":"Inicio","end-placeholder":"Fin","picker-options":_vm.pickerOptions},model:{value:(_vm.monthRange),callback:function ($$v) {_vm.monthRange=$$v},expression:"monthRange"}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-3 my-1 p-1"},[_c('el-select',{staticClass:"w-100",attrs:{"disable":"","multiple":"","filterable":"","placeholder":`Por ${_vm.params.country}`},on:{"change":(value) => {
                                _vm.selectAll(value, 'countries', 'countries');
                            }},model:{value:(_vm.payload.countries),callback:function ($$v) {_vm.$set(_vm.payload, "countries", $$v)},expression:"payload.countries"}},[_c('el-option',{attrs:{"label":"Todos","value":"all"}}),_vm._v(" "),_vm._l((_vm.countries),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})})],2)],1),_vm._v(" "),_c('div',{staticClass:"col-md-3 my-1 p-1"},[_c('el-select',{staticClass:"w-100",attrs:{"disable":"","multiple":"","filterable":"","placeholder":`Por ${_vm.params.place}`},on:{"change":(value) => {
                                _vm.selectAll(value, 'place', 'places');
                            }},model:{value:(_vm.payload.places),callback:function ($$v) {_vm.$set(_vm.payload, "places", $$v)},expression:"payload.places"}},[_c('el-option',{attrs:{"label":"Todos","value":"all"}}),_vm._v(" "),_vm._l((_vm.places),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})})],2)],1),_vm._v(" "),_c('div',{staticClass:"col-md-3 my-1 p-1"},[_c('el-select',{staticClass:"w-100",attrs:{"disable":"","multiple":"","filterable":"","placeholder":`Por ${_vm.params.local}`},on:{"change":(value) => {
                                _vm.selectAll(value, 'local', 'locals');
                            }},model:{value:(_vm.payload.locals),callback:function ($$v) {_vm.$set(_vm.payload, "locals", $$v)},expression:"payload.locals"}},[_c('el-option',{attrs:{"label":"Todos","value":"all"}}),_vm._v(" "),_vm._l((_vm.locals),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})})],2)],1),_vm._v(" "),_c('div',{staticClass:"col-md-3 my-1 p-1"},[_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"¿Es denuncia?"},model:{value:(_vm.payload.isSuggestion),callback:function ($$v) {_vm.$set(_vm.payload, "isSuggestion", $$v)},expression:"payload.isSuggestion"}},[_c('el-option',{attrs:{"label":"Ver denuncias","value":"false"}}),_vm._v(" "),(_vm.auth.isSuggestor || _vm.auth.isSuper)?_c('el-option',{attrs:{"label":"Ver sugerencias","value":"true"}}):_vm._e()],1)],1),_vm._v(" "),(_vm.payload.isSuggestion == 'false')?_c('div',{staticClass:"col-md-3 my-1 p-1"},[_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"Por tipo","multiple":"","filterable":""},on:{"change":(value) => {
                                _vm.selectAll(value, 'types', 'complaintType');
                            }},model:{value:(_vm.payload.types),callback:function ($$v) {_vm.$set(_vm.payload, "types", $$v)},expression:"payload.types"}},[_c('el-option',{attrs:{"label":"Todos","value":"all"}}),_vm._v(" "),_vm._l((_vm.complaintType),function(type){return _c('el-option',{key:type.id,attrs:{"label":type.type,"value":type.id}})})],2)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-md-3 my-1 p-1"},[_c('el-select',{staticClass:"w-100",attrs:{"disable":"","multiple":"","filterable":"","placeholder":"Por estado"},on:{"change":(value) => {
                                _vm.selectAll(value, 'state', 'complaintState');
                            }},model:{value:(_vm.payload.state),callback:function ($$v) {_vm.$set(_vm.payload, "state", $$v)},expression:"payload.state"}},[_c('el-option',{attrs:{"label":"Todos","value":"all"}}),_vm._v(" "),_vm._l((_vm.complaintState),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})})],2)],1),_vm._v(" "),_c('div',{staticClass:"col-md-3 my-1 p-1"},[_c('el-select',{staticClass:"w-100",attrs:{"disable":"","multiple":"","filterable":"","placeholder":"Por anonimato"},on:{"change":(value) => {
                                _vm.selectAll(
                                    value,
                                    'anonymous',
                                    'optionsAnonimus'
                                );
                            }},model:{value:(_vm.payload.anonymous),callback:function ($$v) {_vm.$set(_vm.payload, "anonymous", $$v)},expression:"payload.anonymous"}},[_c('el-option',{attrs:{"label":"Todos","value":"all"}}),_vm._v(" "),_vm._l((_vm.optionsAnonimus),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})})],2)],1),_vm._v(" "),_c('div',{staticClass:"col-md-12 my-1 p-1 d-flex align-items-center justify-content-end"},[_c('button',{staticClass:"btn btn-info text-white mr-2",on:{"click":_vm.filter}},[_vm._v("\n                        Filtrar\n                    ")]),_vm._v(" "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Descargar","placement":"top"}},[_c('el-button',{staticClass:"btn-big-icon h-100",attrs:{"type":"success","plain":"","icon":"big-icon el-icon-download"},on:{"click":_vm.downloadComplaints}})],1),_vm._v(" "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"limpiar","placement":"top"}},[_c('el-button',{staticClass:"btn-big-icon h-100",attrs:{"plain":"","icon":"big-icon el-icon-brush"},on:{"click":_vm.clean}})],1)],1)])],2)],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('h3',{staticClass:"col-md-12 text-center"},[_vm._v("\n            Clasificación de las "+_vm._s(_vm.title)+" según\n        ")]),_vm._v(" "),_c('div',{staticClass:"col-md-12 my-2"},[(_vm.haveGraphData(_vm.dataIncidentType))?_c('bar-chart',{attrs:{"chart-data":_vm.dataIncidentType ?? [],"styles":_vm.myStyleType,"options":{
                    ..._vm.options,
                    onClick: _vm.redirectToComplaint,
                    name: 'type',
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 3,
                                },
                            },
                        ],
                    },
                    title: {
                        display: true,
                        text: 'Tipos de denuncia',
                        fontSize: 16,
                        fontColor: '#212529',
                    },
                }}}):_c('div',{staticClass:"no-data"},[_c('p',[_vm._v("Tipos de denuncia")]),_vm._v(" "),_c('small',[_vm._v("No hay datos disponibles")])])],1)]),_vm._v(" "),_c('div',{staticClass:"row my-4"},[_c('div',{staticClass:"col-md-4 d-flex justify-content-center"},[(_vm.haveGraphData(_vm.dataAnonymous))?_c('pie-chart',{attrs:{"chart-data":_vm.dataAnonymous ?? [],"options":{
                    ..._vm.optionsBasic,
                    onClick: _vm.redirectToComplaint,
                    name: 'anonymous',
                    title: {
                        display: true,
                        text: 'Anonimato',
                        fontSize: 16,
                        fontColor: '#212529',
                    },
                    legend: {
                        position: 'bottom',
                    },
                },"styles":_vm.myStyles}}):_c('div',{staticClass:"no-data"},[_c('p',[_vm._v("Anonimato")]),_vm._v(" "),_c('small',[_vm._v("No hay datos disponibles")])])],1),_vm._v(" "),_c('div',{staticClass:"col-md-4 d-flex justify-content-center"},[(_vm.haveGraphData(_vm.dataIncidentState))?_c('pie-chart',{attrs:{"chart-data":_vm.dataIncidentState ?? [],"options":{
                    ..._vm.optionsBasic,
                    onClick: _vm.redirectToComplaint,
                    name: 'state',
                    title: {
                        display: true,
                        text: 'Estado de denuncia',
                        fontSize: 16,
                        fontColor: '#212529',
                    },
                    legend: {
                        position: 'bottom',
                    },
                },"styles":_vm.myStyles}}):_c('div',{staticClass:"no-data"},[_c('p',[_vm._v("Estado de denuncia")]),_vm._v(" "),_c('small',[_vm._v("No hay datos disponibles")])])],1),_vm._v(" "),_c('div',{staticClass:"col-md-4 d-flex justify-content-center"},[(_vm.haveGraphData(_vm.dataInvestigator))?_c('pie-chart',{attrs:{"chart-data":_vm.dataInvestigator ?? [],"options":{
                    ..._vm.optionsBasic,
                    onClick: _vm.redirectToComplaint,
                    name: 'investigator',
                    title: {
                        display: true,
                        text: 'Investigador asignado',
                        fontSize: 16,
                        fontColor: '#212529',
                    },
                    legend: { position: 'bottom' },
                },"styles":_vm.myStyles}}):_c('div',{staticClass:"no-data"},[_c('p',[_vm._v("Investigador asignado")]),_vm._v(" "),_c('small',[_vm._v("No hay datos disponibles")])])],1)]),_vm._v(" "),(_vm.haveDataIncidentscountries)?_c('div',{staticClass:"row justify-content-center mt-5"},[_c('div',{staticClass:"col-md-12"},[_c('p',{staticClass:"text-center h6 font-bold"},[_vm._v(_vm._s(_vm.params.country))])]),_vm._v(" "),_vm._l((_vm.dataIncidentsCountries),function(item,key){return _c('div',{key:key,class:`col p-3 ${_vm.haveGraphData(item) ? '' : 'd-none'}`},[_c('h6',{staticClass:"text-center"},[_vm._v(_vm._s(item.country))]),_vm._v(" "),(_vm.haveGraphData(item))?_c('bar-chart',{attrs:{"chart-data":item ?? [],"styles":_vm.myStylesCountries,"options":{
                    ..._vm.options,
                    onClick: _vm.redirectToComplaint,
                    name: 'countries',
                    tooltip: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                _vm.console.log(tooltipItem, data);
                                return 'hola';
                            },
                        },
                    },
                }}}):_vm._e()],1)})],2):_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"no-data"},[_c('p',[_vm._v(_vm._s(_vm.params.country))]),_vm._v(" "),_c('small',[_vm._v("No hay datos disponibles")])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 my-1"},[_c('hr'),_vm._v(" "),_c('h5',{staticClass:"text-left"},[_vm._v("\n                Cantidad de "+_vm._s(_vm.title)+"\n                presentados por mes en el período indicado\n            ")]),_vm._v(" "),_c('div',{staticClass:"justify-content-center text-center"},[_c('line-chart',{attrs:{"chart-data":_vm.datacollectionMonth ?? [],"options":_vm.options,"styles":_vm.myStyles}})],1)])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 my-1"},[_c('hr'),_vm._v(" "),_c('h5',{staticClass:"text-left"},[_vm._v("\n                Cantidad total de "+_vm._s(_vm.title)+"\n                presentados en los últimos 12 meses\n            ")]),_vm._v(" "),_c('div',{staticClass:"justify-content-center text-center"},[_c('line-chart',{attrs:{"chart-data":_vm.datacollection ?? [],"options":_vm.options,"styles":_vm.myStyles}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }