<template>
    <div class="div-complaint col-md-9 mx-auto mt-5 text-left">
        <complaint-form-steps :step="step" />
        <!--  Step 1 -->
        <complaint-form-step1
            v-if="step == 1"
            :countries="countries"
            :oldData="this.step1Data"
            :people="people"
            :complaints_types="complaints_types"
            :next="handleGoStep"
            :setId="handleSetId"
            :setStepData="handleSetStep1Data"
            :params="params"
        />

        <!-- step suggestion -->
        <complaint-form-step2a
            v-if="step == 'a'"
            :complaint_id="this.complaint_id"
            :complaint="this.complaint"
            :oldData="this.step2Data"
            :lastStep="this.lastStep"
            :next="handleGoStep"
            :setId="handleSetId"
            :setStepData="handleSetStep2Data"
            :params="params"
        />
        <!-- step complaint detail -->
        <complaint-form-step2b
            v-if="step == 'b'"
            :complaint_id="this.complaint_id"
            :complaint="this.complaint"
            :oldData="this.step2Data"
            :lastStep="this.lastStep"
            :places="places"
            :locals="locals"
            :behaviors="behaviors"
            :next="handleGoStep"
            :setId="handleSetId"
            :setStepData="handleSetStep2Data"
            :params="params"
        />
        <!-- load file and extra -->
        <complaint-form-step3
            v-if="step == 3"
            :complaint_id="this.complaint_id"
            :complaint="this.complaint"
            :lastStep="this.lastStep"
            :next="handleGoStep"
            :setStepData="handleSetResume"
            :recaptcha="recaptcha"
            :params="params"
        />
        <!-- load file and extra -->
        <complaint-form-step4
            v-if="step == 4"
            :next="handleInitializeData"
            :resume="this.resume"
            :params="params"
        />
    </div>
</template>
<script>
export default {
    props: [
        "locals",
        "places",
        "complaints_types",
        "people",
        "countries",
        "behaviors",
        "recaptcha",
        "params",
    ],
    data() {
        return {
            step: "1",
            lastStep: "1",
            complaint_id: 0,
            step1Data: {},
            step2Data: {},
            resume: {},
            complaint: {},
        };
    },
    methods: {
        handleGoStep(step) {
            this.lastStep = this.step;
            this.step = step;
            const element = document.getElementById("init_form");
            element.scrollIntoView({ behavior: "smooth" });
        },
        handleSetId(id, complaint) {
            this.complaint_id = id;
            this.complaint = complaint;
        },
        handleSetStep1Data(data) {
            this.step1Data = data;
        },
        handleSetStep2Data(data) {
            this.step2Data = data;
        },
        handleSetResume(data) {
            this.resume = data;
        },
        handleInitializeData(data) {
            this.step = "1";
            this.lastStep = "1";
            this.complaint_id = 0;
            this.step1Data = {};
            this.step2Data = {};
            this.resume = {};
        },
    },
};
</script>
