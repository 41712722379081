<template>
    <div>
        <el-alert
            class="my-5"
            title="Su denuncia ha sido enviada"
            type="success"
            center
            show-icon
            :closable="false"
        />
        <h5 class="text-center">
            Por favor anotar los siguientes datos para consultar por el estado
            de la denuncia.
        </h5>
        <p class="my-3 text-center">El código de denuncia es:</p>
        <h3 class="my-3 text-center">{{ resume.id }}</h3>
        <p class="my-3 text-center">La contraseña es:</p>
        <h3 class="my-3 text-center">{{ resume.pass }}</h3>
        <el-row type="flex" justify="center">
            <el-button type="primary" class="mt-5 my-auto" @click="out()"
                >Finalizar</el-button
            >
        </el-row>
    </div>
</template>

<script>
export default {
    props: ["params", "resume", "next"],
    methods: {
        out() {
            window.location.href = "/";
        },
    },
};
</script>
