<style>
.input-color {
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
}
.input-color > .el-input__inner {
    border: 0;
    padding: 0;
    width: 2rem;
    height: 2rem;
}
</style>

<template>
    <div class="bg-light mt-3 px-md-4 px-3 py-2">
        <div>
            <template slot="title"
                ><h5 class="pl-3">
                    <i class="el-icon-plus mr-2" />Nuevo Ajuste
                </h5>
            </template>

            <el-form
                id="local_form"
                ref="ruleForm"
                :rules="rules"
                :model="newSetting"
                @submit.prevent="submitForm('ruleForm')"
                status-icon
            >
                <div class="row px-3 pt-3 d-flex justify-content-center">
                    <div class="col-md-6">
                        <el-form-item
                            class="d-flex"
                            prop="color_employees"
                            id="color_employees"
                        >
                            <label
                                >Seleccione el color a mostrarse en
                                Empleador</label
                            >
                            <el-input
                                class="input-color"
                                type="color"
                                v-model="newSetting.color_employees"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="color_providers"
                            id="color_providers"
                        >
                            <label
                                >Seleccione el color a mostrarse en
                                Proveedores</label
                            >
                            <el-input
                                class="input-color"
                                type="color"
                                v-model="newSetting.color_providers"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="color_stakeholders"
                            id="color_stakeholders"
                        >
                            <label
                                >Seleccione el color a mostrarse en
                                Stakeholders</label
                            >
                            <el-input
                                class="input-color"
                                type="color"
                                v-model="newSetting.color_stakeholders"
                            />
                        </el-form-item>
                    </div>
                </div>
                <div class="row px-3 pb-4">
                    <div class="col text-center">
                        <button
                            @click.prevent="submitForm('ruleForm')"
                            class="btn btn-info text-white mx-auto"
                        >
                            Actualizar
                        </button>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    props: ["setting"],
    data() {
        return {
            activeNames: [],
            newSetting: {
                color_employees: this.$props.setting.color_employees,
                color_providers: this.$props.setting.color_providers,
                color_stakeholders: this.$props.setting.color_stakeholders,
            },
            rules: {
                color_employees: [
                    {
                        required: true,
                        message: "Debe seleccionar un color",
                        trigger: ["change", "blur"],
                    },
                ],
                color_providers: [
                    {
                        required: true,
                        message: "Debe seleccionar un color",
                        trigger: ["change", "blur"],
                    },
                ],
                color_stakeholders: [
                    {
                        required: true,
                        message: "Debe seleccionar un color",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            waiting: false,
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.submit();
                } else {
                    this.goToError(rules);
                }
            });
        },
        clearForm() {
            this.setting = {
                color_employees: "",
                color_providers: "",
                color_stakeholders: "",
            };
            this.image = null;
            this.$refs["ruleForm"].resetFields();
        },
        submit() {
            this.waiting = true;
            const url = `${window.location.origin}/actualizar-colores`;
            axios
                .post(url, this.newSetting)
                .then((res) => {
                    this.waiting = false;
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    console.error(error);
                    this.$message.error(
                        "Ha ocurrido un error al editar el ajuste"
                    );
                });
        },
    },
};
</script>
