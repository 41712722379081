<template>
    <div class="bg-light mt-3 px-md-4 px-3 py-2">
        <div>
            <template slot="title"
                ><h5 class="pl-3">
                    <i class="el-icon-plus mr-2" />Nuevo Ajuste
                </h5>
            </template>

            <el-form
                id="local_form"
                ref="ruleForm"
                :rules="rules"
                :model="newSetting"
                @submit.prevent="submitForm('ruleForm')"
                status-icon
            >
                <div class="row px-3 pt-3 d-flex justify-content-between">
                    <div class="col-md-6 px-3">
                        <el-form-item prop="country" id="country">
                            <label>Texto de reemplazo para país</label>
                            <el-input
                                type="text"
                                v-model="newSetting.country"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-md-6 px-3">
                        <el-form-item prop="countries" id="countries">
                            <label>Texto de reemplazo para países</label>
                            <el-input
                                type="text"
                                v-model="newSetting.countries"
                            />
                        </el-form-item>
                    </div>
                </div>
                <div class="row px-3 pt-3 d-flex justify-content-between">
                    <div class="col-md-6 px-3">
                        <el-form-item prop="place" id="place">
                            <label>Texto de reemplazo para lugar o zona</label>
                            <el-input type="text" v-model="newSetting.place" />
                        </el-form-item>
                    </div>
                    <div class="col-md-6 px-3">
                        <el-form-item prop="places" id="places">
                            <label
                                >Texto de reemplazo para lugares o zonas</label
                            >
                            <el-input type="text" v-model="newSetting.places" />
                        </el-form-item>
                    </div>
                </div>
                <div class="row px-3 pt-3 d-flex justify-content-between">
                    <div class="col-md-6 px-3">
                        <el-form-item prop="local" id="local">
                            <label
                                >Texto de reemplazo para local u oficina</label
                            >
                            <el-input type="text" v-model="newSetting.local" />
                        </el-form-item>
                    </div>
                    <div class="col-md-6 px-3">
                        <el-form-item prop="locals" id="locals">
                            <label
                                >Texto de reemplazo para locales u
                                oficinas</label
                            >
                            <el-input type="text" v-model="newSetting.locals" />
                        </el-form-item>
                    </div>
                </div>
                <div class="row px-3 pb-4">
                    <div class="col text-center">
                        <button
                            @click.prevent="submitForm('ruleForm')"
                            class="btn btn-info text-white mx-auto"
                        >
                            Actualizar
                        </button>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    props: ["setting"],
    data() {
        return {
            activeNames: [],
            newSetting: {
                country: this.$props.setting.country,
                countries: this.$props.setting.countries,
                place: this.$props.setting.place,
                places: this.$props.setting.places,
                local: this.$props.setting.local,
                locals: this.$props.setting.locals,
            },
            rules: {
                country: [
                    {
                        required: true,
                        message: "Debe completar este campo",
                        trigger: ["change", "blur"],
                    },
                ],
                countries: [
                    {
                        required: true,
                        message: "Debe completar este campo",
                        trigger: ["change", "blur"],
                    },
                ],
                place: [
                    {
                        required: true,
                        message: "Debe completar este campo",
                        trigger: ["change", "blur"],
                    },
                ],
                places: [
                    {
                        required: true,
                        message: "Debe completar este campo",
                        trigger: ["change", "blur"],
                    },
                ],
                local: [
                    {
                        required: true,
                        message: "Debe completar este campo",
                        trigger: ["change", "blur"],
                    },
                ],
                locals: [
                    {
                        required: true,
                        message: "Debe completar este campo",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            waiting: false,
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.submit();
                } else {
                    this.goToError(rules);
                }
            });
        },
        clearForm() {
            this.setting = {
                business_name: "",
                contact_email: undefined,
                country_id: undefined,
                countries: [],
                language_id: undefined,
                languages: [],
                allow_anonymous: false,
                allow_quick: false,
                fileList: [],
                file: [],
                active: undefined,
            };
            this.image = null;
            this.$refs["ruleForm"].resetFields();
        },
        submit() {
            this.waiting = true;
            const url = `${window.location.origin}/actualizar-etiquetas`;
            axios
                .post(url, this.newSetting)
                .then((res) => {
                    this.waiting = false;
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    console.error(error);
                    this.$message.error(
                        "Ha ocurrido un error al editar el ajuste"
                    );
                });
        },
    },
};
</script>
